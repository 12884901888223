/**
 * CkButtonGroup Component
 *
 * `CkButtonGroup` is a custom form item component from AntDesign that renders a group of buttons.
 * The button group can be arranged in an orientation you specify.
 * Every option in the group has a label and can have an icon. When an option is selected, it triggers an event and you can handle the selected value on click.
 *
 * @component
 * @example
 * <CkButtonGroup
 *   options={[
 *     { label: "Option 1", icon: "add", selected: true, value: "1" },
 *     { label: "Option 2", icon: "edit", selected: false, value: "2" }
 *   ]}
 *   size="full"
 *   onClick={value => console.log(value)}
 * />
 *
 * @extends {FormItemProps}
 *
 * @property {string} className - Optional: Additional CSS class for the form item wrapper
 * @property {string} buttonClassName - Optional: Additional CSS class for the group item (button)
 * @property {CkButtonGroupOptionProps[]} options - Array of button options to be included in the group, each having a label, icon, selected state, and value
 * @property {"minimal"|"full"} size - Optional: Determines the size of the button group, defaults to "minimal" 
 * @property {boolean} triggerGTMOnClick - Optional: Determines whether to trigger Google Tag Manager event upon click
 * @property {Function} onClick - Function to handle event when an option (button) is clicked
 * @property {OrientationProps | ResponsiveOrientationProps} buttonsDirection - Determines orientation of the button group
 */
import React, { FC, ReactNode } from "react";
import { CkIcon, CkIconNames } from "../../atoms";
import { Form, FormItemProps, Button, ButtonProps } from "antd";
import {
  translateOrientation,
  OrientationProps,
  ResponsiveOrientationProps,
} from "../../../utils";
import { gtmSetId } from "../../../../utilities/loadGoogleTagManager";
import "./styles.css";

export interface CkButtonGroupOptionProps extends ButtonProps {
  /**
   * Text to use for the option
   */
  label: string;
  /**
   * Optional:
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  icon?: ReactNode | string;
  /**
   * Value that represent the option
   */
  value: string | number;
}
export interface CkButtonGroupProps extends FormItemProps {
  /**
   * Optional: Additional CSS class for the form item wrapper
   */
  className?: string;
  /**
   * Optional: Additional CSS class for the group item (button)
   */
  buttonClassName?: string;
  /**
   * Array of button options to be included in the group, each having a label, icon, selected state, and value
   */
  options?: CkButtonGroupOptionProps[];
  /**
   * Optional: Determines the size of the button group, defaults to "minimal" 
   */
  size?: "minimal" | "full";
  /**
   * Optional: Determines whether to trigger Google Tag Manager event upon click
   */
  triggerGTMOnClick?: boolean;
  /**
   * Function to handle event when an option (button) is clicked
   */
  onClick?: (value: string | number) => void;
  /**
   * Current value for the button group
   */
  value?: string | number;
  /**
   * Determines orientation of the button group
   */
  buttonsDirection?: OrientationProps | ResponsiveOrientationProps;
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * Options extends AntD Button, see full docs on https://4x.ant.design/components/button/
 */
const CkButtonGroup: FC<CkButtonGroupProps> = ({
  size = "minimal",
  className,
  buttonClassName,
  options,
  triggerGTMOnClick,
  onClick,
  value,
  buttonsDirection,
  ...props
}) => (
  <Form.Item
    className={[
      "ck-button-group",
      `size-${size}`,
      ...(buttonsDirection ? [translateOrientation(buttonsDirection)] : []),
      ...(className ? [className] : []),
    ].join(" ")}
    {...props}
  >
    {options &&
      options.map((option, index) => (
        <Button
          key={`ck-button-group-item-${option.value}-${index}`}
          className={[
            "ck-button-group-item",
            ...(buttonClassName ? [buttonClassName] : []),
            ...(value === option.value ? ["selected"] : []),
            ...(option.icon ? ["iconed"] : []),
          ].join(" ")}
          {...(option.icon && typeof option.icon === "string"
            ? { icon: <CkIcon name={option.icon as CkIconNames} /> }
            : {})}
          {...(option.icon && typeof option.icon !== "string"
            ? { icon: option.icon }
            : {})}
          onClick={(e) => {
            onClick && onClick(option.value);
            props.id && triggerGTMOnClick && gtmSetId(e.currentTarget);
            
          }}
        >
          {option.label}
        </Button>
      ))}
  </Form.Item>
);

export default CkButtonGroup;
